import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-provider-login',
  templateUrl: './provider-login.component.html',
  styleUrls: ['./provider-login.component.scss']
})
export class ProviderLoginComponent implements OnInit {
  currentLang: Lang = 'en';
  currentRouteMap: any;
  constructor(private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }

  ngOnInit(): void {}
}
