import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-covid-resources',
  templateUrl: './covid-resources.component.html',
  styleUrls: ['./covid-resources.component.scss']
})
export class CovidResourcesComponent implements OnInit {
  accordion: string;
  preventioncontent: string;
  subContent: string;
  currentLang: Lang = 'en';
  currentRouteMap: any;
  constructor(private services: ContentDataResolver, private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }

  ngOnInit(): void {
    this.subContent = '';
    this.services.contentData.subscribe(res => {
      if (res) {
        let data = res.find((ele: any) => ele.id === 129);
        this.accordion = data.content.rendered;
        this.preventioncontent = data.content.rendered;
      }
    });
  }
}
