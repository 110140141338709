<div>
  <section id="hero" class="text-center d-flex relative">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow">
          {{ 'general.header.connecting' | translate }}
        </h1>
        <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2">
          {{ 'general.header.prevent' | translate }}<br />
          {{ 'general.header.youNeed' | translate }}
        </h1>
        <div class="line-vt"></div>
        <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block">
          {{ 'general.header.Description' | translate }}
        </div>

        <div class="help-box wow animate__animated animate__fadeIn animate__slow">
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="vt-bg-trans-logo"></div>
  </section>
  <img src="../../../assets/bnr-general-prevention.jpg" class="img-fluid mt-2 mb-2 sw-mob" />

  <section id="prescription-reach-out">
    <div class="bg-white-vt relative pre-section-bg">
      <div class="container-fluid w-92 pt-5 pb-5">
        <div class="row">
          <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow" style="z-index: 9">
            <div class="sce-head1">{{ 'general.connectionPrevention.title' | translate }}</div>
            <div class="line-vt-100"></div>

            <h2 class="font-weight-bold">{{ 'general.connectionPrevention.header' | translate }}</h2>
            <div class="line-vt"></div>
            <div
              id="prevention-content"
              innerHTML="{{ currentLang === 'en' ? contentFirst : ('general.connectionPrevention.subHeader' | translate) }}"
            ></div>
            <p innerHTML="{{ currentLang === 'en' ? contentSecond : ('general.connectionPrevention.description1' | translate) }}"></p>
          </div>
          <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
            <div
              id="prevention-sub-content"
              innerHTML="{{ currentLang === 'en' ? subContent : ('general.connectionPrevention.description2' | translate) }}"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <img src="../../../assets/bnr2-prevention-mob.jpg" class="img-fluid mt-2 mb-2 sw-mob" />

  <section id="prescription-box-4" class="box-general-prevention">
    <div class="container-fluid w-92 card-p mt-40">
      <div class="row">
        <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
          <img src="../../../assets/prevention-1.jpg" class="img-fluid" />
          <h3 class="thmb-heading">{{ 'general.blocks.card1.title' | translate }}</h3>
          <div class="line-vt"></div>
          <p class="line-h-16 mt-4 min-h-70">
            <small>{{ 'general.blocks.card1.description' | translate }}</small>
          </p>
          <div class="line-vt-100 mb-10"></div>
          <a href="https://survey.alchemer.com/s3/5089490/1545c40385eb" target="_blank" class="vt-color-2 link">{{
            'general.blocks.card1.link1' | translate
          }}</a>
          <a
            href="https://www.healthvermont.gov/alcohol-drugs/services/prescription-drug-disposal"
            class="vt-color-2 link"
            target="_blank"
            >{{ 'general.blocks.card1.link2' | translate }}</a
          >
          <a href="https://www.healthvermont.gov/response/safe-needle-disposal-vermont" target="_blank" class="vt-color-2 link">{{
            'general.blocks.card1.link3' | translate
          }}</a>
        </div>

        <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
          <img src="../../../assets/prevention-2.jpg" class="img-fluid" />
          <h3 class="thmb-heading">{{ 'general.blocks.card2.title' | translate }}</h3>
          <div class="line-vt"></div>
          <p class="line-h-16 mt-4 min-h-70">
            <small>{{ 'general.blocks.card2.description' | translate }}</small>
          </p>
          <div class="line-vt-100 mb-10"></div>
          <a href="https://www.healthvermont.gov/RxAware" target="_blank" class="vt-color-2 link">{{
            'general.blocks.card2.link' | translate
          }}</a>
        </div>

        <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
          <img src="../../../assets/prevention-3.jpg" class="img-fluid" />
          <h3 class="thmb-heading">{{ 'general.blocks.card3.title' | translate }}</h3>
          <div class="line-vt"></div>
          <p class="line-h-16 mt-4 min-h-70">
            <small>{{ 'general.blocks.card3.description' | translate }}</small>
          </p>
          <div class="line-vt-100 mb-10"></div>
          <a
            href="https://www.healthvermont.gov/alcohol-drug-abuse/programs-services/prevention-programs"
            target="_blank"
            class="vt-color-2 link"
            >{{ 'general.blocks.card3.link' | translate }}</a
          >
        </div>

        <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
          <img src="../../../assets/prevention-4.jpg" class="img-fluid" />
          <h3 class="thmb-heading">{{ 'general.blocks.card4.title' | translate }}</h3>
          <div class="line-vt"></div>
          <p class="line-h-16 mt-4 min-h-70">
            <small>{{ 'general.blocks.card4.description' | translate }}</small>
          </p>
          <div class="line-vt-100 mb-10"></div>
          <a href="https://www.parentupvt.org/" target="_blank" class="vt-color-2 link">{{ 'general.blocks.card4.link' | translate }}</a>
        </div>
      </div>
    </div>
  </section>

  <section id="prescription-for-you" class="mt-40 relative gen-preven">
    <div class="vt-bg-trans-logo-rev"></div>
    <div class="container-fluid w-100">
      <div class="row">
        <div class="col-md-5 mycshide img-section-hfy-vt"></div>

        <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
          <div class="sce-head1">{{ 'general.prevention.title' | translate }}</div>
          <div class="line-vt-100"></div>

          <h2 class="font-weight-bold">{{ 'general.prevention.header' | translate }}</h2>
        </div>
        <div class="col-md-4 mycs4 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call">
          <p class="line-h-20 mt-2">
            <small>{{ 'general.prevention.description' | translate }}</small>
          </p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
