import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';
import {environment} from 'src/environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentLang: Lang = 'en';
  currentRouteMap: any;
  url:any;
  constructor(private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }
  ngOnInit(): void {
    this.url = environment.url;
  }
}
