import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';
import { ContentDataResolver } from 'src/app/resolvers/contentdata.resolver';

@Component({
  selector: 'app-general-prevention',
  templateUrl: './general-prevention.component.html',
  styleUrls: ['./general-prevention.component.scss']
})
export class GeneralPreventionComponent implements OnInit {
  title: string;
  content: string;
  subContent: string;
  contentFirst: string;
  contentSecond: string;
  currentLang: Lang = 'en';
  currentRouteMap: any;
  constructor(private services: ContentDataResolver, private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }

  ngOnInit(): void {
    this.subContent = '';
    this.services.contentData.subscribe(res => {
      if (res) {
        let data = res.find((ele: any) => ele.id === 42);
        this.title = data.title.rendered;
        this.contentFirst = data.content.rendered.split('\n\n\n\n')[0];
        this.contentSecond = data.content.rendered.split('\n\n\n\n')[1];
        this.subContent = data.acf.second_column;
      }
    });
  }
}
