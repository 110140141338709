import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Lang } from '../Constants/app.model';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isShow: boolean = false;
  currentLang: Lang = 'en';
  currentRouteMap: any;
  currentRoute: string;
  translateRouteMap: any;
  url:any;
  constructor(private route: Router, private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }
  ngOnInit(){
    this.url = environment.url;
  }
  ngAfterViewInit() {
    $('ul.navbar-nav')
      .find('a')
      .on('click', function (e: any) {
        if (e.target.getAttribute('href')) {
          if (e.target.getAttribute('href').includes('app/')) {
            $('button.navbar-toggler').click();
          }
        }
      });
  }

  navigate(url: string, home?: string): void {
    if (home) {
      const url = this.currentLang === 'en' ? '/app' : '/app/es';
      this.route.navigate([url]);
    } else {
      this.route.navigate([url]);
    }
  }

  selectedLang(lang: Lang) {
    this.dataService.getCurrentRouteMap(lang).subscribe(res => {
      this.translateRouteMap = res;
      const urlPrefix = this.currentLang === 'en' ? '/app/es' : '/app',
        urlWithoutPrefix = this.dataService.currentRoute.value.replace(/^\/app(\/es)?/, ''),
        urlSegments = urlWithoutPrefix.split('/');

      const urlSegmentKeys = urlSegments.map(segment => {
        const keyArr = Object.keys(this.dataService.currentRouteMap.value),
          valArr = Object.values(this.dataService.currentRouteMap.value),
          index = valArr.findIndex(val => val === segment);
        return index > -1 ? keyArr[index] : segment;
      });

      const translatedSegments = urlSegmentKeys.map(segment => {
        return this.translateRouteMap[segment] || segment;
      });

      const redirectUrl = `${urlPrefix}${translatedSegments.join('/')}`;

      setTimeout(() => {
        this.navigate(redirectUrl);
      }, 0);
      this.translateRouteMap = null;
      this.dataService.setCurrentLang(lang);
    });
  }
}
