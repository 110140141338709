<footer id="footer" class="col-md-12 myclass3">
  <div class="container">
    <div class="row">
      <div class="col-sm-5 border-b-h2 pl-0">
        <div class="pb-3">
          <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['home'] }}">
            <img *ngIf="currentLang == 'en'" class="img-fluid mb-1" src="../../assets/VT001_VT_Helplink_Logo_VT_Helplink_footer_rest.svg" alt="" width="200px" />
            <img *ngIf="currentLang == 'es'" class="img-fluid mb-1" src="../../assets/VT_Helplink_footer_SP.png" alt="" width="200px" />
          </a>
        </div>
      </div>
      <div class="mt-1 d-767">
        <small>{{ 'footer.callingInfo.text' | translate }}</small>
      </div>
      <!-- <div class="col-sm-1 "></div> -->
      <div class="col-sm-6 col50 border-b-h2 offset-1 set-top-acc mycl18 p-0">
        <ul class="pb-1">
          <li>
            <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['aboutUs'] }}">{{
              'footer.links.accessibility' | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['aboutUs'] }}">{{
              'footer.links.termsOfUse' | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['aboutUs'] }}">{{
              'footer.links.privacyPolicy' | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['contactUs'] }}">{{
              'footer.links.contactUs' | translate
            }}</a>
          </li>
          <li>
            <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['aboutUs'] }}">{{
              'footer.links.aboutUs' | translate
            }}</a>
          </li>
        </ul>
      </div>
      <div class="mt-1 s-767">
        <small>{{ 'footer.callingInfo.text' | translate }}</small>
      </div>
    </div>

    <div class="clearfix"></div>
    <div class="row mt-100 relative">
      <div class="col-lg-8 logo-hr">
        <a href="https://hria.org/">
          <img id="hrialogo" class="hria-logo img-fluid" src="../../assets/HRiA-Logo-Full-White.svg" alt="" />
        </a>
        <a href="https://www.healthvermont.gov/alcohol-drugs">
          <img id="hrialogo" class="hria-logo img-fluid" src="../../assets/VT001_VT_Helplink_Logo_VDH_rest.svg" alt="" />
        </a>
      </div>
      <div class="col-lg-4 mob-social mycl4">
        <div class="d-flex justify-content-end">
          <div>
            <h6>{{ 'footer.socialMedia.getConnected' | translate }}</h6>
          </div>
          <div class="ml-3">
            <a target="_blank" href="https://twitter.com/VTHelplink">
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </div>
          <div class="ml-3">
            <a target="_blank" href="https://www.facebook.com/VTHelplink/">
              <span class="facebook"></span>
            </a>
          </div>
          <div class="ml-3">
            <a target="_blank" href="https://www.instagram.com/vthelplink/">
              <span class="instagram"></span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row copyright-section">
      <div class="col-md-6">
        <div>
          <a href="https://nebulogic.com" target="_blank">
            <img style="width: 110px; height: 30px" src="../../../assets/footer-nl-logo.png" />
          </a>
        </div>
        <p class="mt-3">&copy; {{ fullyear }} {{ 'footer.copyright.text' | translate }}</p>
      </div>
    </div>

    <div class="clearfix"></div>
  </div>

  <script async src="https://tag.simpli.fi/sifitag/f533fea0-7dd7-0139-b0a2-06a60fe5fe77"></script>
  <button class="btn btn-primary scrollbutton" id="ScrollUp" *ngIf="isShow" (click)="gotoTop()">
    <img id="idhslogo" src="assets/up-arrow.png" alt="" width="15" />
  </button>
</footer>
