<section id="hero" class="text-center d-flex home-sld">
  <div class="col-md-8 hero-slide hide-mob"></div>
  <div class="col-md-4 right-set">
    <div class="hero-container">
      <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
        {{ 'home.header.connecting' | translate }}
      </h1>
      <h1
        class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
        style="visibility: visible; animation-name: fadeInDown"
      >
        {{ 'home.header.services' | translate }}<br />
        {{ 'home.header.youNeed' | translate }}
      </h1>
      <div class="line-vt home-line"></div>
      <div
        class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mb-30"
        data-wow-delay=".0s"
        style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
      >
        {{ 'home.header.description' | translate }}
      </div>

      <div
        class="help-box wow animate__animated animate__fadeIn animate__slow"
        data-wow-delay=".8s"
        style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
      >
        <div class="bt1 btn65">
          <a href="tel:8025655465" class="btn-get-started hero-button"> {{ 'shared.buttons.call' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</section>
<img src="../../../assets/home-bg-black.png" class="img-fluid sw-mob" />
<div class="container-fluid w-100 pl-4p bg-vt-welcome relative">
  <div class="row align-items-center">
    <div class="col-md-3 mycs12 wow animate__animated animate__fadeInLeft animate__slow">
      <div class="sce-head1 sec-head-mob">{{ 'home.welcome.title' | translate }}</div>
      <div class="line-vt-100"></div>

      <h2 class="font-weight-bold mycl8">{{ 'home.welcome.heading1' | translate }}</h2>
      <h2 class="font-weight-bold mycs3">{{ 'home.welcome.heading2' | translate }}</h2>
      <div class="line-vt mycl7"></div>
    </div>

    <div class="col-md-3 mycs14 wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block st-page">
      <h3 class="vt-m mycl6">
        {{ 'home.welcome.description1' | translate }}
      </h3>
      <p>
        <small> {{ 'home.welcome.description2' | translate }} </small>
      </p>
    </div>

    <div class="col-md-6 img-welcome-vt mycl9"></div>
  </div>
  <div class="vt-bg-trans-logo"></div>
</div>
<div class="bg-white-vt box-4" style="background-color: #f6f2f1">
  <div class="container-fluid w-92 mycl13">
    <div class="row align-items-center2">
      <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
        <div class="sce-head1 sec-head-mob">{{ 'home.findHelp.title' | translate }}</div>
        <div class="line-vt-100" style="margin: 20px 0 40px 0"></div>
        <h2 class="font-weight-bold">{{ 'home.findHelp.header' | translate }}</h2>
        <div class="line-vt mycl10"></div>
        <h3 class="vt-m mt-3 mycl6">{{ 'home.findHelp.subHeader' | translate }}</h3>
        <p>
          <small>{{ 'home.findHelp.description' | translate }} </small>
        </p>
        <a href="{{url}}/findhelp?lang={{currentLang}}" target="_blank" class="vt-color-2 link mycl12"
          >{{ 'home.findHelp.link' | translate }}
        </a>
      </div>
      <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4">
        <img src="../../../assets/treatment.png" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block1.card1.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="tretment-cnt para-margin">
          <small>{{ 'home.block1.card1.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/34a60db3-e7c0-4f36-9a41-b76fe24ddd3c?lang={{currentLang}}"
          target="_blank"
          class="vt-color-2 link mycl12"
          >{{ 'home.block1.card1.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/medications.png" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block1.card2.title' | translate }}</h3>
        <div class="line-vt mycl10 medication-bar"></div>
        <p class="para-margin">
          <small>{{ 'home.block1.card2.description' | translate }}<br /><br /></small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/00c96ceb-7e4d-45ea-9300-dacb1db4853a?lang={{currentLang}}"
          target="_blank"
          class="vt-color-2 link mycl12"
          >{{ 'home.block1.card2.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/harmreduction.png" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block1.card3.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="para-margin">
          <small>{{ 'home.block1.card3.description' | translate }}<br /><br /></small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/2067e362-25c4-4256-86bc-6ce3e4000078?lang={{currentLang}}"
          target="_blank"
          class="vt-color-2 link mycl12"
          >{{ 'home.block1.card3.link' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
<div class="bg-white-vt box-4" style="background-color: #fcf8f9">
  <div class="container-fluid w-92 mycl13">
    <div class="row align-items-center2">
      <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
        <div class="sce-head1 sec-head-mob">{{ 'home.reachOut.title' | translate }}</div>
        <div class="line-vt-100 middle-line" style="margin: 20px 0 40px 0"></div>
        <h2 class="font-weight-bold">{{ 'home.reachOut.header' | translate }}</h2>
        <div class="line-vt mycl10"></div>
        <h3 class="vt-m mt-3 mycl6">
          {{ 'home.reachOut.subHeader' | translate }}
        </h3>
        <p>
          <small>{{ 'home.reachOut.description1' | translate }}</small>
        </p>
        <p>
          <small>{{ 'home.reachOut.description2' | translate }}</small>
        </p>
      </div>
      <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4">
        <img src="../../../assets/gethelp-1.jpg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block2.card1.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="help-content para-margin">
          <small>{{ 'home.block2.card1.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['needHelp'] }}"
          class="vt-color-2 link mycl12"
          >{{ 'home.block2.card1.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/gethelp-2.jpg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block2.card2.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="loved-content para-margin">
          <small>{{ 'home.block2.card2.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['myLovedOneNeedsHelp'] }}"
          class="vt-color-2 link mycl12"
          >{{ 'home.block2.card2.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/gethelp-3.png" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'home.block2.card3.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="para-margin">
          <small>{{ 'home.block2.card3.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['myClientNeedsHelp'] }}"
          class="vt-color-2 link mycl12"
          >{{ 'home.block2.card3.link' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
<div class="container-fluid w-100 pl-4p bg-vt-welcome relative">
  <div class="row">
    <div class="col-md-3 col-sm-12 mycs16 wow animate__animated animate__fadeInLeft animate__slow op-overdose staysafe-col">
      <div class="sce-head1 sec-head-mob mt-10">{{ 'home.staySafe.title' | translate }}</div>
      <div class="line-vt-100"></div>
      <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['saferUse'] }}" style="color: #000"
        ><h2 class="font-weight-bold">{{ 'home.staySafe.header' | translate }}</h2></a
      >
      <div class="line-vt home-line"></div>
      <p>
        <small>{{ 'home.staySafe.description' | translate }}</small>
      </p>
    </div>

    <div class="col-md-3 col-sm-12 mycs15 mt wow animate__animated animate__fadeIn animate__slow staysafe-col">
      <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">{{ 'home.block3.card1.title' | translate }}</h3>
      <p class="mt-3 home-p">
        <small>{{ 'home.block3.card1.description' | translate }}</small>
      </p>
      <a
        href="{{url}}/spa_result/incident_id/0fb64a4d-10bb-4058-bd31-1150a2eedbf2?lang={{currentLang}}"
        target="_blank"
        class="vt-color-2 link mb-1 mycl14 color-link"
        >{{ 'home.block3.card1.link' | translate }}</a
      >
      <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">{{ 'home.block3.card2.title' | translate }}</h3>
      <p class="mt-3 home-p">
        <small>{{ 'home.block3.card2.description' | translate }}</small>
      </p>
      <a
        href="{{url}}/spa_result/incident_id/0794a21e-0ee1-4be4-9abe-5ffca2387a18?lang={{currentLang}}"
        target="_blank"
        class="vt-color-2 link mb-1 mycl14 color-link"
        >{{ 'home.block3.card2.link' | translate }}</a
      >
      <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">{{ 'home.block3.card3.title' | translate }}</h3>
      <p class="mt-3 home-p">
        <small>{{ 'home.block3.card3.description' | translate }}</small>
      </p>
      <a
        href="{{url}}/spa_result/incident_id/d09981e8-eb80-40c0-b306-fd05b067faf0?lang={{currentLang}}"
        target="_blank"
        class="vt-color-2 link mb-1 mycl14 color-link"
        >{{ 'home.block3.card3.link' | translate }}</a
      >
      <!-- <a href="https://vthelplink.org/app/account/opa_result/incident_id/0169bac2-7560-4d0a-afbf-79eb3e86f6a5#description"  class="vt-color-2 link mb-1 mycl14">Get naloxone.</a> 
                        <a routerLink="/app/overdose-prevention"  class="vt-color-2 link mycl14">Learn more about overdose prevention.</a>  -->
    </div>
    <div class="col-md-6 img-section-preventing-opioid mycl9 mymt2"></div>
  </div>
  <div class="vt-bg-trans-logo"></div>
</div>
<div class="container-fluid w-100 bg-vt-pop">
  <div class="row">
    <div class="col-md-5 img-section-pop-vt hide-mob mycs20"></div>

    <div class="col-md-3 mycs17 wow animate__animated animate__fadeInLeft animate__slow pt-5 op-overdose">
      <div class="sce-head1 sec-head-mob">{{ 'home.resource.title' | translate }}</div>
      <div class="line-vt-100"></div>

      <h2 class="font-weight-bold">{{ 'home.resource.header1' | translate }}</h2>
      <div class="line-vt mycl10"></div>
    </div>

    <div class="col-md-3 mycs18 wow animate__animated animate__fadeInDown animate__slow d-sm-block pt-6">
      <h3 class="vt-m mt16-sec5 pt-2-vo1024 mycl6">
        {{ 'home.resource.header2' | translate }}
      </h3>
      <p class="mt-3 mycl14">
        <small>{{ 'home.resource.description' | translate }}</small>
      </p>
      <a
        routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['pregnantParenting'] }}"
        class="vt-color-2 link mycl14"
        >{{ 'home.resource.link' | translate }}</a
      >
    </div>

    <img src="../../../assets/bg-vt-section-pregnant.jpg" class="col-md-6 mycs19 img-fluid sw-mob m-l-r--15 mymt2" />
  </div>
</div>
<div class="relative bg-white-vt p0m0" id="bg-wheat">
  <div class="container-fluid w-100 myclas2">
    <div class="row">
      <div class="ml-4-pt"></div>
      <div class="col-md-3 mycs21 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 mmt-2 op-overdose">
        <div class="sce-head1 sec-head-mob">{{ 'home.spreadWord.title' | translate }}</div>
        <div class="line-vt-100"></div>

        <h2 class="font-weight-bold">{{ 'home.spreadWord.header1' | translate }}</h2>
        <div class="line-vt mycl10"></div>
      </div>

      <div class="col-md-3 mycs22 wow animate__animated animate__fadeInDown animate__slow d-sm-block mt-40 pt-6 bt-call mb-100">
        <h3 class="vt-m mycl6">{{ 'home.spreadWord.header2' | translate }}</h3>

        <p>
          <small>{{ 'home.spreadWord.description' | translate }}</small>
        </p>
        <!-- <a href="https://vadic.org/product-category/vermont-resources/vt-helplink/" target="_blank" class="vt-color-2 link  mycl12">Order materials now. </a>  -->
      </div>
      <div class="col img-free-material-vt"></div>
      <div class="col-md-6 mycs19 sw-mob hide-l-992 mycl15">
        <img src="../../../assets/bg-free-material.jpg" class="img-fluid sw-mob" />
      </div>
    </div>
  </div>
  <div class="vt-bg-trans-logo-rev"></div>
</div>

<div class="container-fluid w-100 bg-vt-welcome myclas2">
  <div class="row">
    <div class="col-md-5 mycs20 img-section-hfy-vt hide-mob"></div>

    <div class="col-md-3 mycl17 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 op-overdose">
      <div class="sce-head1 sec-head-mob">{{ 'home.connect.title' | translate }}</div>
      <div class="line-vt-100"></div>
      <h2 class="font-weight-bold">{{ 'home.connect.header' | translate }}</h2>
      <div class="line-vt mycl10"></div>
    </div>
    <div class="col-md-3 mycl17 wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mt-40 pt-6 bt-call">
      <p>
        <small>{{ 'home.connect.description' | translate }}</small>
      </p>

      <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
        <div class="bt1 btn65">
          <a href="tel:8025655465" class="btn-get-started hero-button"> {{ 'shared.buttons.call' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect"> {{ 'shared.buttons.text' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
        </div>
      </div>
    </div>
    <div class="col-md-6 mycs19 sw-mob hide-l-992 mycl15">
      <img src="../../../assets/bg-vt-section-hfy.png" class="img-fluid sw-mob" />
    </div>
    <!-- <img src="../../../assets/bg-vt-section-hfy.png" class="img-fluid mycs23 sw-mob m-l-r--15"> -->
  </div>
</div>
