import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AboutTreatmentComponent } from './pages/about-treatment/about-treatment.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ClientneedHelpComponent } from './pages/clientneed-help/clientneed-help.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { CovidResourcesComponent } from './pages/covid-resources/covid-resources.component';
import { FindhelpComponent } from './pages/findhelp/findhelp.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { GeneralPreventionComponent } from './pages/general-prevention/general-prevention.component';
import { HarmReductionComponent } from './pages/harm-reduction/harm-reduction.component';
import { HomeComponent } from './pages/home/home.component';
import { LovedneedHelpComponent } from './pages/lovedneed-help/lovedneed-help.component';
import { NeedhelpComponent } from './pages/needhelp/needhelp.component';
import { OverdosePreventionComponent } from './pages/overdose-prevention/overdose-prevention.component';
import { PregnantParentingComponent } from './pages/pregnant-parenting/pregnant-parenting.component';
import { ProviderLoginComponent } from './pages/provider-login/provider-login.component';
import { TreatmentDataResolver } from './resolvers/about-treatment.resolver';
import { ContentDataResolver } from './resolvers/contentdata.resolver';
import { SpaResultComponent } from './spa-result/spa-result.component';

const routes: Routes = [
  { path: '', redirectTo: 'app/home', pathMatch: 'full' },
  { path: 'app', redirectTo: 'app/home', pathMatch: 'full' },
  { path: 'app/home', component: HomeComponent, pathMatch: 'full' },
  { path: 'app/needhelp', component: NeedhelpComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/my-loved-one-needs-help', component: LovedneedHelpComponent },
  { path: 'app/my-client-needs-help', component: ClientneedHelpComponent },
  { path: 'app/treatment-recovery', component: AboutTreatmentComponent, resolve: { data: TreatmentDataResolver } },
  { path: 'app/pregnant-parenting', component: PregnantParentingComponent },
  { path: 'app/general-prevention', component: GeneralPreventionComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/overdose-prevention', component: OverdosePreventionComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/covid-resource', component: CovidResourcesComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/login', component: ProviderLoginComponent },
  { path: 'app/forgot-password', component: ForgotpasswordComponent },
  { path: 'app/account/findhelp', component: FindhelpComponent },
  { path: 'app/aboutus', component: AboutusComponent },
  { path: 'app/contactus', component: ContactusComponent },
  { path: 'app/account/opa_result/incident_id/:incidentid', component: SpaResultComponent, pathMatch: 'full' },
  { path: 'app/account/opa_result', component: SpaResultComponent, pathMatch: 'full' },
  { path: 'app/saferuse', component: HarmReductionComponent, pathMatch: 'full' },

  // Spanish Routes
  { path: 'app/es', redirectTo: 'app/es/hogar', pathMatch: 'full' },
  { path: 'app/es/hogar', component: HomeComponent, pathMatch: 'full' },
  { path: 'app/es/necesita-ayuda', component: NeedhelpComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/es/mi-ser-querido-necesita-ayuda', component: LovedneedHelpComponent },
  { path: 'app/es/mi-cliente-necesita-ayuda', component: ClientneedHelpComponent },
  { path: 'app/es/tratamiento-recuperacion', component: AboutTreatmentComponent, resolve: { data: TreatmentDataResolver } },
  { path: 'app/es/embarazo-paternidad', component: PregnantParentingComponent },
  { path: 'app/es/prevencion-general', component: GeneralPreventionComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/es/prevencion-sobredosis', component: OverdosePreventionComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/es/recurso-covid', component: CovidResourcesComponent, resolve: { data: ContentDataResolver } },
  { path: 'app/es/inicio-sesion', component: ProviderLoginComponent },
  { path: 'app/es/olvido-contrasena', component: ForgotpasswordComponent },
  { path: 'app/es/account/encontrar-ayuda', component: FindhelpComponent },
  { path: 'app/es/sobre-nosotros', component: AboutusComponent },
  { path: 'app/es/contactenos', component: ContactusComponent },
  { path: 'app/es/account/opa_result/id-incidente/:incidentid', component: SpaResultComponent, pathMatch: 'full' },
  { path: 'app/es/account/resultado-opa', component: SpaResultComponent, pathMatch: 'full' },
  { path: 'app/es/uso-mas-seguro', component: HarmReductionComponent, pathMatch: 'full' },
  { path: '**', redirectTo: 'app/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule {}
