import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AnswerComponent } from './answer/answer.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HideForLangDirective } from './directives/hide-for-lang.directive';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AboutTreatmentComponent } from './pages/about-treatment/about-treatment.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ClientneedHelpComponent } from './pages/clientneed-help/clientneed-help.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { CovidResourcesComponent } from './pages/covid-resources/covid-resources.component';
import { FindhelpComponent } from './pages/findhelp/findhelp.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { GeneralPreventionComponent } from './pages/general-prevention/general-prevention.component';
import { HarmReductionComponent } from './pages/harm-reduction/harm-reduction.component';
import { HomeComponent } from './pages/home/home.component';
import { LovedneedHelpComponent } from './pages/lovedneed-help/lovedneed-help.component';
import { NeedhelpComponent } from './pages/needhelp/needhelp.component';
import { OverdosePreventionComponent } from './pages/overdose-prevention/overdose-prevention.component';
import { PregnantParentingComponent } from './pages/pregnant-parenting/pregnant-parenting.component';
import { ProviderLoginComponent } from './pages/provider-login/provider-login.component';
import { SafePipe } from './pipes/sanatize.pipe';
import { SpaResultComponent } from './spa-result/spa-result.component';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory(httpClient: HttpClient) {
  if(environment.production){
    return new MultiTranslateHttpLoader(httpClient, [{ prefix: './assets/translate/core_prod/', suffix: '.json' }]);
  }else{
    return new MultiTranslateHttpLoader(httpClient, [{ prefix: './assets/translate/core/', suffix: '.json' }]);
  }
  
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NeedhelpComponent,
    LovedneedHelpComponent,
    ClientneedHelpComponent,
    AboutTreatmentComponent,
    PregnantParentingComponent,
    GeneralPreventionComponent,
    OverdosePreventionComponent,
    CovidResourcesComponent,
    ProviderLoginComponent,
    ForgotpasswordComponent,
    FindhelpComponent,
    AboutusComponent,
    ContactusComponent,
    SafePipe,
    AnswerComponent,
    SpaResultComponent,
    HarmReductionComponent,
    HideForLangDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
