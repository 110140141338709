<div ng-app="odpreventionApp" ng-controller="odpreventionController">
  <section id="hero" class="text-center d-flex relative">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow">
          {{ 'overdose.header.connecting' | translate }}
        </h1>
        <h1 class="wow animate__animated animate__fadeInDown animate__slow vt-color-2">
          {{ 'overdose.header.prevent' | translate }}
        </h1>

        <div class="line-vt"></div>
        <div class="wow mycs13 animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block" data-wow-delay=".0s">
          {{ 'overdose.header.Description' | translate }}
        </div>

        <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s">
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <img src="../../../assets/bnr--od-prevention-mob.jpg" class="img-fluid sw-mob mt-2" />

  <section id="overdose-section-2">
    <div class="bg-white-vt relative">
      <div class="container-fluid w-92 pt-5 pb-5" style="z-index: 9; position: relative">
        <div class="row">
          <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
            <div class="sce-head1">{{ 'overdose.alive.title' | translate }}</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold">{{ 'overdose.alive.header' | translate }}</h2>
            <div class="line-vt"></div>

            <h3 class="vt-m mt-3">{{ 'overdose.alive.subHeader' | translate }}</h3>
            <p class="mt-4">
              <small>
                {{ 'overdose.alive.description.part1' | translate }}
                <a
                  routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['treatmentRecovery'] }}"
                  class="vt-color-2"
                  >{{ 'overdose.alive.description.link' | translate }}</a
                >
                {{ 'overdose.alive.description.part2' | translate }}
              </small>
            </p>
            <div id="leftsideBarData" [innerHTML]="leftsideBarData"></div>
          </div>

          <!------------------------------------------ accordion section ---------------------------------->
          <div class="col-md-6 mycs10 wow animate__animated animate__fadeIn animate__slow">
            <div
              id="accordion"
              class="myaccordion"
              innerHTML="{{ currentLang === 'en' ? accordion : ('overdose.accordion' | translate) }}"
            ></div>
          </div>

          <div class="col-md-3 mycs11 wow animate__animated animate__fadeIn animate__slow">
            <img src="../../../assets/OD_Prevention-img-1.jpg" class="img-fluid" />
            <h3 class="thmb-heading">{{ 'overdose.naloxone.title' | translate }}</h3>
            <div class="line-vt"></div>
            <p>
              <small>
                {{ 'overdose.naloxone.description.part1' | translate }}
                <a href="app/answers/answer_id/2">{{ 'overdose.naloxone.description.link' | translate }}</a>
                {{ 'overdose.naloxone.description.part2' | translate }}
              </small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <a
              href="https://www.youtube.com/watch?list=PLk9-Nj2hBiYrqLKf5pWFwcbhKp3uxKikB&time_continue=56&v=GnGMkJSu4gU&feature=emb_logo"
              target="_blank"
              class="vt-color-2 link"
            >
              {{ 'overdose.naloxone.link1' | translate }}
            </a>
            <a
              href="https://www.healthvermont.gov/sites/default/files/documents/pdf/RESP_Narcan_HowToGiveBrochure2016.pdf"
              target="_blank"
              class="vt-color-2 link"
            >
              {{ 'overdose.naloxone.link2' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="overdose-pre" class="img-section-hfy-vt">
    <div class="container-fluid w-100">
      <div class="row">
        <div class="col-md-5"></div>
        <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
          <div class="sce-head1">{{ 'overdose.precious.title' | translate }}</div>
          <div class="line-vt-100"></div>
          <h2 class="font-weight-bold mycs12">{{ 'overdose.precious.header' | translate }}</h2>
        </div>
        <div class="col-md-4 wow animate__animated animate__fadeIn animate__slow mt-40 pt-6 bt-call">
          <p class="line-h-20 mt-2">
            <small>{{ 'overdose.precious.description' | translate }}</small>
          </p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
        <img src="../../../assets/bg-vt-section-od-prevention.jpg" class="img-fluid sw-mob" />
      </div>
    </div>
    <div class="chat-box"></div>
  </section>
</div>
