<div class="container-fluid mycs4 pregnancy">
  <div class="row">
    <section id="inner-page" class="col-md-12 pandp">
      <div>
        <div class="container mycs-cont">
          <div class="row" id="hero">
            <div class="col-md-7">
              <div class="head01" innerHTML="{{ currentLang === 'en' ? title : ('parent.heading' | translate) }} "></div>
              <div class="line-vt"></div>
              <div innerHTML="{{ currentLang === 'en' ? content : ('parent.description' | translate) }} "></div>
            </div>

            <div class="col-md-5 right-set">
              <div class="hero-container pandp-sec2">
                <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
                  {{ 'parent.header.connecting' | translate }}
                </h1>
                <h1
                  class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
                  style="visibility: visible; animation-name: fadeInDown"
                >
                  {{ 'parent.header.youNeed' | translate }}
                </h1>
                <div class="line-vt"></div>
                <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block" data-wow-delay=".0s">
                  {{ 'parent.header.description' | translate }}
                </div>

                <div
                  class="help-box wow animate__animated animate__fadeIn animate__slow"
                  data-wow-delay=".8s"
                  style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
                >
                  <div class="bt1 btn65">
                    <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
                  </div>
                  <div class="bt2 top" hideForLang="es">
                    <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                      'shared.buttons.text' | translate
                    }}</a>
                  </div>
                  <div class="bt2 top" hideForLang="es">
                    <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div></div>
  </div>
</div>
