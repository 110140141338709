import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lang } from './Constants/app.model';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly baseUrl = 'https://live-vt-helpline.pantheonsite.io';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  selectedLang = new BehaviorSubject<Lang>('en');
  currentRoute = new BehaviorSubject('');
  pageName = new BehaviorSubject('');
  currentRouteMap = new BehaviorSubject<any>({});

  constructor(private http: HttpClient, public translate: TranslateService) {}

  setCurrentLang(lang: Lang = 'en'): void {
    if (lang) {
      this.selectedLang.next(lang);
      localStorage.setItem('Current Language', lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
      this.getCurrentRouteMap(lang).subscribe(res => {
        this.currentRouteMap.next(res);
      });
      if (lang !== 'en') {
        $('body').find('#chat-button,.chat-box').hide();
      } else {
        $('body').find('#chat-button,.chat-box').show();
      }
    }
  }

  getCurrentRouteMap(lang: Lang) {
    return this.http.get(`/assets/translate/route-map/${lang}.json`);
  }

  /* =============== Start English API's =================*/
  getDynamicContentData(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/');
  }
  getLoveOneNeedHelp(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/33');
  }
  getClientNeedHelp(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/35');
  }
  getTreatmentRecovery(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/37');
  }
  getPregnentParenting(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/155');
  }

  getAboutData(): Observable<any> {
    return this.http.get('https://live-vt-helpline.pantheonsite.io/wp-json/wp/v2/pages/7');
  }
  /* =============== End English API's =================*/
}
