import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-lovedneed-help',
  templateUrl: './lovedneed-help.component.html',
  styleUrls: ['./lovedneed-help.component.scss']
})
export class LovedneedHelpComponent implements OnInit {
  title: string;
  content: string;
  contentFirst: string;
  contentSecond: string;
  contentThree: string;
  currentLang: Lang = 'en';
  currentRouteMap: any;
  constructor(private services: DataService) {
    this.services.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.services.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }

  ngOnInit(): void {
    this.services.getLoveOneNeedHelp().subscribe(res => {
      if (res) {
        this.title = res.title.rendered;
        this.content = res.content.rendered;
        this.contentFirst = this.content.split('\n\n\n\n')[0];
        this.contentSecond = this.content.split('\n\n\n\n')[1];
        this.contentThree = this.content.split('\n\n\n\n')[2];
      }
    });
  }
}
