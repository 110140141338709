<br />
<section id="hero" class="text-center d-flex home-sld">
  <!-- <div class="col-md-8 hero-slide hide-mob" ></div> -->
  <div class="col-md-8 hide-mob">
    <div style="padding: 56.25% 0 0 0; position: relative; margin: 10px">
      <iframe
        src="https://player.vimeo.com/video/753845269?h=f69b594292&badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&sidedock=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      ></iframe>
    </div>
  </div>
  <div class="col-md-4">
    <div class="hero-container">
      <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
        {{ 'safe.users.sue.title' | translate }}
      </h1>
      <div class="line-vt home-line"></div>
      <div
        class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mb-30"
        data-wow-delay=".0s"
        style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
      >
        <h5>{{ 'safe.users.sue.header' | translate }}</h5>
        <p>
          {{ 'safe.users.sue.description.part1' | translate }}
          <a href="{{url}}/spa_result/incident_id/0794a21e-0ee1-4be4-9abe-5ffca2387a18?lang={{currentLang}}" target="_blank">
            {{ 'safe.users.sue.description.link1' | translate }}</a
          >
          {{ 'safe.users.sue.description.part2' | translate }}
        </p>
      </div>
      <a class="font-bold" href="https://vimeo.com/747035016/1e10b8a872" target="_blank">
        <svg width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" /></svg
        >&nbsp;{{ 'safe.users.sue.link' | translate }}</a
      >
      <!-- <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                    <div class="bt1"><a href="tel:8025655465" class="btn-get-started hero-button">Call us.
                            802.565.LINK</a>
                    </div>
                    <div class="bt2 top"><button class="btn-get-started hero-button btn-connect"
                            onclick="showchat()">Connect via chat.</button>
                    </div>
                </div> -->
    </div>
  </div>
</section>
<div class="col-md-12 sw-mob">
  <div style="padding: 56.25% 0 0 0; position: relative; margin: 10px">
    <iframe
      src="https://player.vimeo.com/video/753845269?h=f69b594292&badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&sidedock=0"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</div>
<br />
<!-- <img src="../../../assets/home-bg-black-mob.jpg" class="img-fluid sw-mob"> -->
<div class="container-fluid w-100 pl-4p bg-vt-welcome relative mt-15">
  <div class="row align-items-center2">
    <div class="col-md-3 mycs12 wow animate__animated animate__fadeInLeft animate__slow" style="margin-top: -4px">
      <div class="line-vt-100"></div>
      <h5 class="sce-head1 sec-head-mob">{{ 'safe.users.ryan.title' | translate }}</h5>
      <div class="line-vt home-line"></div>
      <h5 class="font-weight-bold mycl8">{{ 'safe.users.ryan.header' | translate }}</h5>
      <p>
        {{ 'safe.users.ryan.description.part1' | translate }}<br />{{ 'safe.users.ryan.description.part2' | translate }}
        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['generalPrevention'] }}"
          >{{ 'safe.users.ryan.description.link' | translate }}
        </a>
      </p>
      <a class="font-bold" href="https://vimeo.com/747033649/9c58a84cd1" target="_blank">
        <svg width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" /></svg
        >&nbsp;{{ 'safe.users.ryan.link' | translate }}</a
      >
      <!-- <div class="line-vt mycl7"></div> -->
    </div>
    <div class="col-md-9 mycl9">
      <div style="padding: 56.25% 0 0 0; position: relative; margin: 10px">
        <iframe
          src="https://player.vimeo.com/video/753774748?h=442c27f3de&badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&sidedock=0"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        ></iframe>
      </div>
    </div>
  </div>
  <!-- <div class="vt-bg-trans-logo"></div> -->
</div>
<section id="hero" class="text-center d-flex home-sld mt-15">
  <!-- <div class="col-md-8 hero-slide hide-mob" ></div> -->
  <div class="col-md-8 hide-mob">
    <div style="padding: 56.25% 0 0 0; position: relative; margin: 10px">
      <iframe
        src="https://player.vimeo.com/video/753854415?h=8e2ca5f8ba&badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&sidedock=0"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
        title="vt_helplink_-_ryan (1080p)"
      ></iframe>
    </div>
  </div>
  <div class="col-md-4 right-set2">
    <div class="hero-container">
      <h5 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
        {{ 'safe.users.theresa.title' | translate }}
      </h5>
      <div class="line-vt home-line"></div>
      <div
        class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mb-30"
        data-wow-delay=".0s"
        style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
      >
        <h5>{{ 'safe.users.theresa.header' | translate }}</h5>
        <p>{{ 'safe.users.theresa.description.part1' | translate }}</p>
      </div>
      <a class="font-bold" href="https://vimeo.com/747030587/5b6ddb81aa" target="_blank">
        <svg width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" /></svg
        >&nbsp;{{ 'safe.users.theresa.link' | translate }}</a
      >
      <!-- <div class="help-box wow animate__animated animate__fadeIn animate__slow" data-wow-delay=".8s"
                        style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">
                        <div class="bt1"><a href="tel:8025655465" class="btn-get-started hero-button">Call us.
                                802.565.LINK</a>
                        </div>
                        <div class="bt2 top"><button class="btn-get-started hero-button btn-connect"
                                onclick="showchat()">Connect via chat.</button>
                        </div>
                    </div> -->
    </div>
  </div>
</section>
<div class="col-md-12 sw-mob">
  <div style="padding: 56.25% 0 0 0; position: relative; margin: 10px">
    <iframe
      src="https://player.vimeo.com/video/753854415?h=8e2ca5f8ba&badge=0&autopause=0&player_id=0&app_id=58479&title=0&byline=0&portrait=0&sidedock=0"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      title="vt_helplink_-_ryan (1080p)"
    ></iframe>
  </div>
</div>
<div class="container-fluid w-100 pl-4p bg-vt-welcome relative mt-15" style="background: #e4f0f0 !important">
  <div class="row align-items-center2">
    <div class="col-md-3 col-sm-12 mycs16 wow animate__animated animate__fadeInLeft animate__slow op-overdose mt-15">
      <div class="sce-head1 sec-head-mob">{{ 'safe.saferUse.title' | translate }}</div>
      <div class="line-vt-100"></div>
      <h2 class="font-weight-bold">{{ 'safe.saferUse.header1' | translate }}</h2>
      <div class="line-vt mycl10"></div>
    </div>
    <div class="col-md-3 col-sm-12 mycs15 mt wow animate__animated animate__fadeIn animate__slow mt-15">
      <h3 class="vt-m col-sm-12 mt46-sec4 p0m0 mycl6">
        {{ 'safe.saferUse.header2' | translate }}
      </h3>

      <p class="mt-3">
        <small>{{ 'safe.saferUse.description1' | translate }}</small>
      </p>
      <p class="mt-3">
        <small>{{ 'safe.saferUse.description2' | translate }}</small>
      </p>
    </div>
    <div class="col-md-6 img-section-preventing-opioid mycl9 mymt2"></div>
  </div>
  <div class="vt-bg-trans-logo"></div>
</div>
<div class="bg-white-vt box-4">
  <div class="container-fluid w-92 mycl13">
    <div class="row align-items-center2">
      <!-- <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
                    <div class="sce-head1 sec-head-mob">Reach out.</div>
                    <div class="line-vt-100"></div>    
                    <h2 class="font-weight-bold">Find help.</h2>
                    <div class="line-vt mycl10"></div>
                    <h3 class="vt-m mt-3  mycl6">Whether you’re seeking help for yourself or someone you care about, VT Helplink can connect you to the services you need.  </h3>
                    <p><small>VT Helplink is a free resource for Vermonters, providing confidential, non-judgmental support and referrals to treatment, recovery, and other services.</small></p>
                    <p><small>Our Helplink Specialists can help you navigate Vermont’s treatment and recovery system, whether or not you have insurance.</small></p>
    
                </div> -->

      <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4">
        <img src="../../../assets/gethelp-1.jpeg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'safe.blocks.ssp.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="para-margin">
          <small>{{ 'safe.blocks.ssp.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          _ngcontent-ake-c9=""
          class="vt-color-2 link mycl12"
          href="{{url}}//spa_result/incident_id/d09981e8-eb80-40c0-b306-fd05b067faf0?lang={{currentLang}}"
          target="_blank"
          >{{ 'safe.blocks.ssp.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 mycl11 wow animate__animated animate__fadeIn animate__slow inm-mt4">
        <img src="../../../assets/gethelp-2.jpeg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'safe.blocks.naloxone.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="ssp1 para-margin">
          <small>{{ 'safe.blocks.naloxone.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/0fb64a4d-10bb-4058-bd31-1150a2eedbf2?lang={{currentLang}}"
          class="vt-color-2 link mycl12"
          target="_blank"
          >{{ 'safe.blocks.naloxone.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/gethelp-3.jpeg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'safe.blocks.harmReduction.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="ssp2 para-margin">
          <small>{{ 'safe.blocks.harmReduction.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/0794a21e-0ee1-4be4-9abe-5ffca2387a18?lang={{currentLang}}"
          class="vt-color-2 link mycl12"
          target="_blank"
          >{{ 'safe.blocks.harmReduction.link' | translate }}</a
        >
      </div>
      <div class="col-md-3 mycs5 wow animate__animated animate__fadeIn animate__slow">
        <img src="../../../assets/gethelp-4.jpeg" class="img-fluid" />
        <h3 class="thmb-heading">{{ 'safe.blocks.syringe.title' | translate }}</h3>
        <div class="line-vt mycl10"></div>
        <p class="ssp3 para-margin">
          <small>{{ 'safe.blocks.syringe.description' | translate }}</small>
        </p>
        <div class="line-vt-100 mb-10"></div>
        <a
          href="{{url}}/spa_result/incident_id/d09981e8-eb80-40c0-b306-fd05b067faf0?lang={{currentLang}}"
          class="vt-color-2 link mycl12"
          target="_blank"
          >{{ 'safe.blocks.syringe.link' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-fluid w-100 bg-vt-pop">
            <div class="row ">	
                <div class="col-md-5 img-section-pop-vt hide-mob mycs20"></div>
        
                <div class="col-md-3 mycs17 wow animate__animated animate__fadeInLeft animate__slow pt-5 op-overdose">
                    <div class="sce-head1 sec-head-mob">Get resources for you and your family.</div>
                    <div class="line-vt-100"></div>
        
                    <h2 class="font-weight-bold">Are you pregnant or parenting?</h2>
                    <div class="line-vt mycl10"></div>
                </div>
        
                <div class="col-md-3 mycs18 wow animate__animated animate__fadeInDown animate__slow  d-sm-block pt-6">
                    <h3 class="vt-m mt16-sec5 pt-2-vo1024 mycl6">Whether you’re a parent, or soon to be one, VT Helplink can connect you to the resources you need. </h3>
                    <p class="mt-3 mycl14"><small>Many treatment options and other supports are available to help you and your family stay healthy and stay together.</small></p>
                        <a routerLink="/app/pregnant-parenting" class="vt-color-2 link mycl14" >See pregnancy & parenting resources.</a> 
                </div>
        
                <img src="../../../assets/bg-vt-section-pregnant.jpg" class="col-md-6 mycs19 img-fluid sw-mob m-l-r--15 mymt2">
        </div>
        </div>
        <div class="relative bg-white-vt p0m0">
                <div class="container-fluid w-100 myclas2">
                        <div class="row ">	
                    
            <div class="ml-4-pt"></div>
                    <div class="col-md-3 mycs21 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 mmt-2 op-overdose">
                        <div class="sce-head1 sec-head-mob">Spread the word.</div>
                        <div class="line-vt-100"></div>
            
                        <h2 class="font-weight-bold" >Order free materials.</h2>
                        <div class="line-vt mycl10"></div>
                    </div>
            
            
                    <div class="col-md-3 mycs22 wow animate__animated animate__fadeInDown animate__slow  d-sm-block mt-40 pt-6 bt-call  mb-100">	
                        <h3 class="vt-m mycl6">Promote the use of the Helplink in your community.</h3>
                                 
                        <p><small>Help connect people to the services they need with VT Helplink cards, posters, and other items.</small></p>
                         <a href="https://vadic.org/product-category/vermont-resources/vt-helplink/" target="_blank" class="vt-color-2 link  mycl12">Order materials now. </a> 
                         
                    </div>
                    <div class="col img-free-material-vt"> </div>
            <div class="col-md-6 mycs19 sw-mob hide-l-992 mycl15">
            <img src="../../../assets/bg-free-material.jpg" class="img-fluid sw-mob">
             </div>
            
                </div>
                </div>
                <div class="vt-bg-trans-logo-rev"></div>
            </div> -->
<div class="container-fluid w-100 bg-vt-welcome myclas2" style="background: #e4f0f0 !important">
  <div class="row">
    <div class="col-md-5 mycs20 img-section-hfyvt hide-mob"></div>

    <div class="col-md-3 mycl17 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 op-overdose">
      <div class="sce-head1 sec-head-mob">{{ 'safe.notAlone.title' | translate }}</div>
      <div class="line-vt-100"></div>

      <h2 class="font-weight-bold">{{ 'safe.notAlone.heading' | translate }}</h2>
      <div class="line-vt mycl10"></div>
    </div>
    <div class="col-md-3 mycl17 wow animate__animated animate__fadeInDown animate__slow intro-copy d-sm-block mt-40 pt-6 bt-call">
      <p>
        <small>{{ 'safe.notAlone.description' | translate }}</small>
      </p>

      <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
        <div class="bt1 btn65">
          <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
        </div>
        <div class="bt2 top" hideForLang="es">
          <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
        </div>
      </div>
    </div>
    <img src="../../../assets/bg-vt-section-hfy.jpeg" class="img-fluid mycs23 sw-mob m-l-r--15" />
  </div>
</div>
