<header id="header" class="header-home pb0">
  <div class="container-fluid w-92">
    <div class="row justify-content-between">
      <div class="col-6.5 border-b-h m-o-b mycl5 header-col">
        <div id="logo" class="pull-left" [ngClass]="{ es: currentLang === 'es' }" (click)="navigate('', 'home')">
          <a> </a>
        </div>
      </div>
      <div class="col-5.5 nav-fl-new pr-0 m-o-b header-col2">
        <div class="mob-numbe-link hide-mob d-flex justify-content-between">
          <div class="call-or-text">
            <span>
              <a href="tel:8025655465">{{ 'header.callOrText' | translate }}</a>
            </span>
            <span *ngIf="currentLang !== 'es'" class="textIcons"></span>
          </div>
          <div class="">
            <button type="button" class="btn btn-get-started" *ngIf="currentLang === 'en'" (click)="selectedLang('es')">Español</button>
            <button type="button" class="btn btn-get-started" *ngIf="currentLang === 'es'" (click)="selectedLang('en')">English</button>
          </div>
        </div>
        <div class="rv-nav">
          <div id="menu_area" class="menu-area">
            <nav class="navbar navbar-light navbar-expand-lg mainmenu" id="navigation">
              <button
                class="navbar-toggler hamburger"
                type="button"
                *ngIf="!isShow"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                (click)="isShow = true"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <button
                  class="navbar-toggler hamburger position-relative"
                  style="top: -22px"
                  *ngIf="isShow"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  (click)="isShow = false"
                >
                  <span class="navbar-toggler-icon1"></span>
                </button>
                <ul class="navbar-nav mr-auto border-b-h">
                  <div class="lang-btns-mobile">
                    <button type="button" class="btn btn-get-started" *ngIf="currentLang === 'en'" (click)="selectedLang('es')">
                      Español
                    </button>
                    <button type="button" class="btn btn-get-started" *ngIf="currentLang === 'es'" (click)="selectedLang('en')">
                      English
                    </button>
                  </div>
                  <li class="dropdown">
                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <u>{{ 'header.findHelp.title' | translate }}</u>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li class="blank-box-nav hide-mob"></li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['needHelp'] }}">{{
                          'header.findHelp.subLinks.iNeedHelp' | translate
                        }}</a>
                      </li>
                      <li>
                        <a
                          routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['myLovedOneNeedsHelp'] }}"
                          >{{ 'header.findHelp.subLinks.lovedNeedHelp' | translate }}</a
                        >
                      </li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['myClientNeedsHelp'] }}">{{
                          'header.findHelp.subLinks.clientNeedHelp' | translate
                        }}</a>
                      </li>
                      <li>
                        <a href="{{url}}/findhelp?lang={{currentLang}}">{{ 'header.findHelp.subLinks.searchForTreatment' | translate }}</a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <u>{{ 'header.treatmentAndRecovery.title' | translate }}</u>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li class="blank-box-nav hide-mob"></li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['treatmentRecovery'] }}">{{
                          'header.treatmentAndRecovery.subLinks.aboutTreatment' | translate
                        }}</a>
                      </li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['pregnantParenting'] }}">{{
                          'header.treatmentAndRecovery.subLinks.pregnantAndParenting' | translate
                        }}</a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['saferUse'] }}">{{
                      'header.saferUse' | translate
                    }}</a>
                  </li>
                  <li class="dropdown">
                    <a href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <u>{{ 'header.prevention.title' | translate }}</u>
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <li class="blank-box-nav hide-mob"></li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['generalPrevention'] }}">{{
                          'header.prevention.subLinks.generalPrevention' | translate
                        }}</a>
                      </li>
                      <li>
                        <a
                          routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['overdosePrevention'] }}"
                          >{{ 'header.prevention.subLinks.overdosePrevention' | translate }}</a
                        >
                      </li>
                      <li>
                        <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['covidResource'] }}">{{
                          'header.prevention.subLinks.covidResource' | translate
                        }}</a>
                      </li>
                    </ul>
                  </li>

                  <li class="sw-mob">
                    <hr />
                  </li>
                  <li class="mob-numbe-link sw-mob position-relative fs-16">
                    <span>
                      <a href="tel:8025655465">{{ 'header.callOrText' | translate }}</a>
                    </span>
                    <span *ngIf="currentLang !== 'es'" class="textIcons" style="top: 16px; right: 0"></span>
                  </li>

                  <li class="provider-login sw-mob">
                    <a href="{{url}}">{{ 'header.providerPortal' | translate }}</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="link-set border-b-h d-flex pl-0 pr-0 hide-mob">
            <div class="provider-login">
              <a href="{{url}}">{{ 'header.providerPortal' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<script type="text/javascript">
  jQuery('.hria-logo, .idhs-logo')
    .mouseenter(function () {
      var src = jQuery(this).attr('src');
      jQuery(this).attr('src', src.replace('REST', 'HOVER'));
    })
    .mouseleave(function () {
      var src = $(this).attr('src');
      jQuery(this).attr('src', src.replace('HOVER', 'REST'));
    });
</script>
<!-- Used for Menu  -->
<script>
  (function ($) {
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      var $subMenu = $(this).next('.dropdown-menu');
      $subMenu.toggleClass('show');

      $(this)
        .parents('li.nav-item.dropdown.show')
        .on('hidden.bs.dropdown', function (e) {
          $('.dropdown-submenu .show').removeClass('show');
        });

      return false;
    });
  })(jQuery);
</script>

<script>
  $(function () {
    $('.navbar-nav >li').hover(
      function () {
        $(this).addClass('highlight').siblings().find('a').addClass('highlight_stay');
      },
      function () {
        $('ul li').removeClass('highlight');
        $('ul li a').removeClass('highlight_stay');
      }
    );
    $('.dropdown-menu li').hover(function () {
      $(this).toggleClass('highlight').siblings().addClass('highlight_stay');
    });
  });

  $(document).on('click', '.cx-close-confirm, .cx-btn.cx-btn-default, .close-st', function () {
    $('.vt-homepage .chat-box').removeClass('active');
  });
  $(document).on('click', '.cx-submit, .cx-end-cancel', function () {
    $('.cx-button-close').removeClass('close-st');
  });
  $(document).on('click', '.cx-end-confirm', function () {
    $('.cx-button-close').addClass('close-st');
  });
  $(document).on('click', '.vt-homepage .chat-box, .btn-connect', function () {
    $('.vt-homepage .chat-box').addClass('active');
    setTimeout(function () {
      $('.cx-button-close').addClass('close-st');
    }, 1000);
  });

  $(document).on('click', '.cx-end-cancel', function () {
    $('.vt-homepage .chat-box').addClass('active');
  });
</script>
