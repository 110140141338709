<div class="container-fluid">
  <div class="row">
    <section id="inner-page" class="col-md-12">
      <div class="container pad0 ptb50">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div class="head01">Login</div>
                <div class="partial-line"></div>
                <p class="top10">If you already have an account, please enter your email address and password to log in.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div id="err_placeholder" style="display: none" class="alert alert-danger"></div>
              </div>
            </div>
            <form id="login_form">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="un">Email Address</label>
                    <input type="text" name="un" id="un" class="form-control" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label for="pw">Password</label>
                    <input type="password" name="pw" id="pw" class="form-control" />
                    <div class="text-right">
                      <a routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['forgotPassword'] }}"
                        >Forgot Password?</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group text-center">
                    <button class="btn-get-started hero-button login-btn">Login</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div class="head01">Account Sign Up</div>
                <div class="partial-line"></div>
              </div>
              <div class="col-sm-12">
                <h4 class="top10">Not registered yet?</h4>
                <p>Sign up below to create a new account. By signing up, you can:</p>
                <ul style="list-style-type: square; padding-left: 30px; margin-top: 20px">
                  <li>View/Edit Provider Information</li>
                  <li>Keep track of your submitted requests</li>
                  <li>Update your profile</li>
                  <li><em>and more...</em></li>
                </ul>
              </div>
              <div class="col-sm-12">
                <span id="are_you">Are you a Service Provider?</span>
                <label><input type="radio" name="asProvider" id="asProvider1" value="Yes" />&nbsp;Yes</label>&nbsp;&nbsp;&nbsp;&nbsp;<label
                  ><input type="radio" name="asProvider" id="asProvider2" value="No" />&nbsp;No</label
                >
                <div class="text-center top10" id="signup_button">
                  <a id="dynamic_signup_link" class="btn-get-started hero-button login-btn" href="#">Sign Up</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
