import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from './data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ilhelpline';

  constructor(private router: Router, public translate: TranslateService, private service: DataService) {
    translate.addLangs(['en', 'es']);
    this.router.events.subscribe(value => {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (value instanceof NavigationEnd) {
        if (window.location.pathname.match('/es/') || window.location.pathname === '/es') {
          this.service.setCurrentLang('es');
        } else {
          this.service.setCurrentLang('en');
        }
        this.service.currentRoute.next(window.location.pathname);
      }
    });
  }
}
