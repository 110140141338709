<div>
  <section id="hero" class="text-center d-flex relative">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
          {{ 'about.header.connecting' | translate }}
        </h1>
        <h1
          class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
          style="visibility: visible; animation-name: fadeInDown"
        >
          {{ 'about.header.youNeed' | translate }}
        </h1>
        <div class="line-vt"></div>
        <div
          class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block"
          data-wow-delay=".0s"
          style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
        >
          {{ 'about.header.description' | translate }}
        </div>

        <div
          class="help-box wow animate__animated animate__fadeIn animate__slow"
          data-wow-delay=".8s"
          style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
        >
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="vt-bg-trans-logo"></div>
  </section>

  <img src="../../../assets/bnr-treatment_Recovery-mob.jpg" class="img-fluid sw-mob" />

  <section id="treatment-recovery">
    <div class="vt-bg-trans-logo sw-mob"></div>
    <div class="bg-white-vt relative">
      <div class="container-fluid p-2 pr-0 pl-5 pt-5 pb-5" style="z-index: 9; position: relative">
        <div class="row">
          <div class="col-md-3 mycs4 p15 p-0 pl-5 wow animate__animated animate__fadeInLeft animate__slow">
            <div class="sce-head1">{{ 'about.recovery.title' | translate }}</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold">{{ 'about.recovery.heading' | translate }}</h2>
            <div class="line-vt"></div>
            <div id="mainContent">
              <p>
                {{ 'about.recovery.description.part1' | translate }}
              </p>
              <p>
                {{ 'about.recovery.description.talk' | translate }}
                <a href="tel:8025655465">{{ 'about.recovery.description.link1' | translate }}</a>
                {{ 'about.recovery.description.part2' | translate }}
                <a href="{{url}}/findhelp?lang={{currentLang}}">{{ 'about.recovery.description.link2' | translate }}</a>
                {{ 'about.recovery.description.part3' | translate }}
              </p>
            </div>
          </div>

          <!-- accordion section  -->

          <div class="col-md-6 mycs4 wow animate__animated animate__fadeIn animate__slow">
            <div
              id="accordion"
              class="myaccordion"
              innerHTML="{{ currentLang === 'en' ? content : ('about.accordion' | translate) }}"
            ></div>
          </div>

          <div class="col-md-3 mycshide wow animate__animated animate__fadeIn animate__slow p-0">
            <img src="../../../assets/img-treatment-recovery.jpg" class="img-fluid mt-50 m-mob-0" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="lovedoneneeds-for-you">
    <div class="container-fluid w-100">
      <div class="row">
        <div class="col-md-5 img-section-hfy-vt hide-mob"></div>

        <div class="col-md-3 pt2-tab wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
          <div class="sce-head1">{{ 'about.hereForYou.title' | translate }}</div>
          <div class="line-vt-100"></div>
          <h2 class="font-weight-bold">{{ 'about.hereForYou.header' | translate }}</h2>
        </div>
        <div class="col-md-4 pt2-tab wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call">
          <p class="line-h-20 mt-2">
            <small>{{ 'about.hereForYou.description' | translate }}</small>
          </p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="col-md-5 img-section-hfy-vt sw-mob"></div>
      </div>
    </div>
  </section>
</div>
