import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../data.service';
@Injectable({
  providedIn: 'root'
})
export class ContentDataResolver implements Resolve<any> {
  contentData: BehaviorSubject<any>;
  constructor(private service: DataService) {
    this.contentData = new BehaviorSubject(null);
  }
  resolve(route: import('@angular/router').ActivatedRouteSnapshot, state: import('@angular/router').RouterStateSnapshot) {
    return this.service.getDynamicContentData().subscribe(res => {
      if (res) {
        this.contentData.next(res);
      }
    });
  }
}
