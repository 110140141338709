import { Component, OnInit } from '@angular/core';
import { Lang } from 'src/app/Constants/app.model';
import { DataService } from 'src/app/data.service';
import { TreatmentDataResolver } from 'src/app/resolvers/about-treatment.resolver';
import {environment} from 'src/environments/environment';
@Component({
  selector: 'app-about-treatment',
  templateUrl: './about-treatment.component.html',
  styleUrls: ['./about-treatment.component.scss']
})
export class AboutTreatmentComponent implements OnInit {
  title: string;
  content: string;
  leftContent: string;
  currentLang: Lang = 'en';
  currentRouteMap: any;
  url:any;
  constructor(private services: TreatmentDataResolver, private readonly dataService: DataService) {
    this.dataService.selectedLang.subscribe(lang => {
      this.currentLang = lang;
    });
    this.dataService.currentRouteMap.subscribe(routeMap => {
      this.currentRouteMap = routeMap;
    });
  }

  ngOnInit(): void {
    this.url = environment.url;
    this.services.treatmentData.subscribe(
      res => {
        if (res !== null) {
          this.title = res.title.rendered;
          this.content = res.content.rendered;
          this.leftContent = res.acf.left_sidebar;
        }
      },
      error => {}
    );
  }
}
