<div>
  <section id="hero" class="text-center d-flex relative">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
          {{ 'aboutUs.header.connecting' | translate }}
        </h1>
        <h1
          class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
          style="visibility: visible; animation-name: fadeInDown"
        >
          {{ 'aboutUs.header.need' | translate }}
        </h1>
        <div class="line-vt"></div>
        <div class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block" data-wow-delay=".0s">
          {{ 'aboutUs.header.description' | translate }}
        </div>

        <div
          class="help-box wow animate__animated animate__fadeIn animate__slow"
          data-wow-delay=".8s"
          style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
        >
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="vt-bg-trans-logo"></div>
  </section>
  <img src="../../../assets/bnr-about-us-mob.jpg" class="img-fluid sw-mob mt-2" />
  <section id="lovedoneneeds-reach-out">
    <div class="bg-white-vt relative">
      <div class="container-fluid pr-0 pt5-abt pt-5 pb-5 w-92" style="z-index: 9; position: relative">
        <div class="row">
          <div class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow">
            <div class="sce-head1">{{ 'aboutUs.connect' | translate }}</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold" id="aboutus-title">{{ 'aboutUs.title' | translate }}</h2>
            <div class="line-vt"></div>
            <div id="mainContent">
              <h3 class="vt-m mt-3" innerHTML="{{ currentLang === 'en' ? headertag : ('aboutUs.heading' | translate) }}"></h3>
            </div>
            <div id="mainContent" innerHTML="{{ currentLang === 'en' ? mainContent : ('aboutUs.description' | translate) }}"></div>
          </div>
          <!-- accordion section start -->
          <div class="col-md-6 mycs10 wow animate__animated animate__fadeIn animate__slow">
            <div id="accordion" class="myaccordion mycs23">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="d-flex align-items-center justify-content-between btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {{ 'aboutUs.accordion.acc1.title' | translate }}
                    </button>
                  </h2>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    <p>
                      <a href="https://www.vermont.gov/policies/accessibility" target="_blank">{{
                        'aboutUs.accordion.acc1.link' | translate
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h2 class="mb-0">
                    <button
                      class="d-flex align-items-center justify-content-between btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {{ 'aboutUs.accordion.acc2.title' | translate }}
                    </button>
                  </h2>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <!--<h3>Assessment</h3>-->
                    <p>
                      {{ 'aboutUs.accordion.acc2.text' | translate }}
                    </p>

                    <h3>{{ 'aboutUs.accordion.acc2.header1' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description1' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header2' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description2.part1' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description2.part2' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description2.part3' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header3' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description3.part1' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description3.part2' | translate
                      }}<a href="#"> {{ 'aboutUs.accordion.acc2.description3.link' | translate }}</a>
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header4' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description4' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header5' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description5' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header6' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description6' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header7' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description7' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header8' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description8' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header9' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description9' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header10' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description10' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc2.header11' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description11.part1' | translate }}
                      <a href="https://vthelplink.org" style="color: #0052cc">{{
                        'aboutUs.accordion.acc2.description11.link' | translate
                      }}</a
                      >  {{ 'aboutUs.accordion.acc2.description11.part2' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description11.part3' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc2.description11.part4' | translate }}
                    </p>
                    <p>{{ 'aboutUs.accordion.acc2.description11.part5' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h2 class="mb-0">
                    <button
                      class="d-flex align-items-center justify-content-between btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      {{ 'aboutUs.accordion.acc3.title' | translate }}
                    </button>
                  </h2>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <p>
                      {{ 'aboutUs.accordion.acc3.text' | translate }}
                    </p>
                    <h3>
                      {{ 'aboutUs.accordion.acc3.header1.part1' | translate }} <br />
                      {{ 'aboutUs.accordion.acc3.header1.part2' | translate }}
                    </h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description1.part1' | translate }}
                    </p>

                    <p>
                      {{ 'aboutUs.accordion.acc3.description1.part2' | translate }}
                    </p>

                    <p>
                      {{ 'aboutUs.accordion.acc3.description1.part3' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc3.header2' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description2.part1' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description2.part2' | translate }}
                    </p>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description2.part3' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc3.header3' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description3' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc3.header4' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description4' | translate }}
                    </p>
                    <h3>{{ 'aboutUs.accordion.acc3.header5' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description5' | translate }}
                    </p>

                    <h3>{{ 'aboutUs.accordion.acc3.header6' | translate }}</h3>
                    <p>
                      {{ 'aboutUs.accordion.acc3.description6' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 mycs11 animate__animated animate__fadeIn animate__slow">
            <!-- bg-free-material.jpg -->
            <img src="../../../assets/bg-free-material.jpg" class="img-fluid" />
            <h3 class="vt-m">{{ 'aboutUs.promote.header' | translate }}</h3>
            <div class="line-vt"></div>
            <p>
              <small>{{ 'aboutUs.promote.description' | translate }}</small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <!-- <a href="https://vadic.org/product-category/vermont-resources/vt-helplink/" target="_blank" class="vt-color-2 link">Order materials now. </a>  -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about-section-connection" class="about-sec3">
    <div class="container-fluid w-100">
      <div class="row">
        <div class="col-md-5 img-section-hfy-vt hide-mob"></div>

        <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5">
          <small>{{ 'aboutUs.confidential.title' | translate }}</small>
          <div class="line-vt-100"></div>

          <h2 class="font-weight-bold">{{ 'aboutUs.confidential.header' | translate }}</h2>
        </div>
        <div class="col-md-4 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call">
          <p class="line-h-20 mt-2">
            <small>{{ 'aboutUs.confidential.description' | translate }}</small>
          </p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
        <!-- /euf/assets/client/img/bg-bottom-about-us.jpg -->
        <img src="../../../assets/bg-bottom-about-us.jpg" class="img-fluid mt-2 mb-2 sw-mob" />
      </div>
    </div>
    <div class="chat-box"></div>
  </section>
</div>
