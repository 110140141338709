<div>
  <section id="hero" class="text-center d-flex">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
          {{ 'lovedOne.header.connecting' | translate }}
        </h1>
        <h1
          class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
          style="visibility: visible; animation-name: fadeInDown"
        >
          {{ 'lovedOne.header.help' | translate }} <br />
          {{ 'lovedOne.header.youNeed' | translate }}
        </h1>
        <div class="line-vt"></div>
        <div
          class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block"
          data-wow-delay=".0s"
          style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
        >
          {{ 'lovedOne.header.Description' | translate }}
        </div>

        <div
          class="help-box wow animate__animated animate__fadeIn animate__slow mt2-4-600"
          data-wow-delay=".8s"
          style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
        >
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="lovedoneneeds-reach-out">
    <div class="bg-white-vt relative myloved">
      <div class="container-fluid w-92 pt-5 pb-5">
        <div class="row">
          <div class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow">
            <div class="sce-head1">{{ 'lovedOne.reachOut.title' | translate }}</div>
            <div class="line-vt-100"></div>
            <h2 class="font-weight-bold">{{ 'lovedOne.reachOut.header' | translate }}</h2>
          </div>
          <div class="col-md-3" id="mainContent">
            <h3
              class="vt-m mt-3"
              innerHTML="{{ currentLang === 'en' ? contentFirst : ('lovedOne.reachOut.description.part1' | translate) }}"
            ></h3>
            <p innerHTML="{{ currentLang === 'en' ? contentSecond : ('lovedOne.reachOut.description.part2' | translate) }} "></p>
            <p innerHTML="{{ currentLang === 'en' ? contentThree : ('lovedOne.reachOut.description.part3' | translate) }} "></p>
          </div>

          <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
            <img src="../../../assets/my-loved-gethelp-1.jpg" class="img-fluid" />
            <h3 class="thmb-heading">{{ 'lovedOne.blocks.treatments.title' | translate }}</h3>
            <div class="line-vt"></div>
            <p>
              <small>{{ 'lovedOne.blocks.treatments.description' | translate }}</small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <a
              routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['treatmentRecovery'] }}"
              class="vt-color-2 link"
              >{{ 'lovedOne.blocks.treatments.link' | translate }}</a
            >
          </div>

          <div class="col-md-3 wow animate__animated animate__fadeIn animate__slow">
            <img src="../../../assets/my-loved-gethelp-2.jpg" class="img-fluid" />
            <h3 class="thmb-heading">{{ 'lovedOne.blocks.preventOpoid.title' | translate }}</h3>
            <div class="line-vt"></div>
            <p>
              <small>{{ 'lovedOne.blocks.preventOpoid.description' | translate }}</small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <a
              routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['overdosePrevention'] }}"
              class="vt-color-2 link"
              >{{ 'lovedOne.blocks.preventOpoid.link' | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="lovedoneneeds-for-you">
    <div class="container-fluid needhelp-sec3 w-100 bg-vt-welcome">
      <div class="row">
        <div class="col-md-5 img-section-hfy-vt"></div>
        <div
          class="col-md-3 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 p20 m0"
          style="visibility: visible; animation-name: fadeInLeft"
        >
          <div class="sce-head1">{{ 'lovedOne.connect.title' | translate }}</div>
          <div class="line-vt-100"></div>
          <h2 class="font-weight-bold">{{ 'lovedOne.connect.header' | translate }}</h2>
        </div>
        <div
          class="col-md-3 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call"
          style="visibility: visible; animation-name: fadeInLeft"
        >
          <p>
            <small>{{ 'lovedOne.connect.description' | translate }}</small>
          </p>
          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
