import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DataService } from '../data.service';

@Directive({
  selector: '[hideForLang]'
})
export class HideForLangDirective implements OnInit {
  @Input('hideForLang') langCode!: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private service: DataService) {}

  ngOnInit(): void {
    this.service.selectedLang.subscribe(() => {
      this.checkLanguage();
    });
  }

  private checkLanguage(): void {
    const currentLang = this.service.selectedLang.value;

    if (currentLang === this.langCode) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'display');
    }
  }
}
