<div ng-app="ineedhelphelpApp" ng-controller="ineedhelpController">
  <section id="hero" class="text-center d-flex">
    <div class="col-md-8 hero-slide hide-mob"></div>
    <div class="col-md-4 right-set">
      <div class="hero-container">
        <h1 class="wow animate__animated animate__fadeInDown animate__slow" style="visibility: visible; animation-name: fadeInDown">
          {{ 'iNeedHelp.header.connecting' | translate }}
        </h1>
        <h1
          class="wow animate__animated animate__fadeInDown animate__slow vt-color-2"
          style="visibility: visible; animation-name: fadeInDown"
        >
          {{ 'iNeedHelp.header.help' | translate }}<br />
          {{ 'iNeedHelp.header.youNeed' | translate }}
        </h1>
        <div class="line-vt"></div>
        <div
          class="wow animate__animated animate__fadeInDown animate__slow intro-copy d-none d-sm-block"
          data-wow-delay=".0s"
          style="visibility: visible; animation-delay: 0s; animation-name: fadeInDown"
        >
          {{ 'iNeedHelp.header.description' | translate }}
        </div>

        <div
          class="help-box wow animate__animated animate__fadeIn animate__slow"
          data-wow-delay=".8s"
          style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn"
        >
          <div class="bt1 btn65">
            <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{ 'shared.buttons.text' | translate }}</a>
          </div>
          <div class="bt2 top" hideForLang="es">
            <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <img src="../../../assets/bnr-i-need-help-mob.jpg" class="img-fluid mt-2 sw-mob" />
  <section id="lovedoneneeds-reach-out">
    <div class="bg-white-vt relative needhelp">
      <div class="container-fluid w-92 pt-5 pb-5">
        <div class="row">
          <div
            class="col-md-3 mycs4 wow animate__animated animate__fadeInLeft animate__slow"
            style="visibility: visible; animation-name: fadeInLeft"
          >
            <div class="sce-head1">{{ 'iNeedHelp.reachOut1.title' | translate }}</div>
            <div class="line-vt-100"></div>
            <h2
              class="font-weight-bold"
              id="ineedhelp-title"
              innerHTML="{{ currentLang === 'en' ? title : ('iNeedHelp.reachOut1.heading' | translate) }}"
            ></h2>
            <h2 class="font-weight-bold" id="ineedhelp-title"></h2>
          </div>
          <div
            class="col-md-3 mycs4 wow animate__animated animate__fadeIn animate__slow"
            id="ineedhelp-content"
            style="visibility: visible; animation-name: fadeIn"
          >
            <h3
              class="vt-m mt-3"
              innerHTML="{{ currentLang === 'en' ? ineedhelpFirstContent : ('iNeedHelp.reachOut1.subHeader' | translate) }}"
            ></h3>
            <p innerHTML="{{ currentLang === 'en' ? ineedhelpScecondContent : ('iNeedHelp.reachOut1.description' | translate) }}"></p>
          </div>
          <div
            class="col-md-3 mycs9 wow animate__animated animate__fadeIn animate__slow"
            style="visibility: visible; animation-name: fadeIn"
          >
            <img src="../../../assets/my-loved-gethelp-1.jpg" class="img-fluid" />
            <h3 class="thmb-heading">{{ 'iNeedHelp.blocks.card1.title' | translate }}</h3>
            <div class="line-vt"></div>
            <p class="min-h-70">
              <small>{{ 'iNeedHelp.blocks.card1.description' | translate }}</small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <a
              routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['treatmentRecovery'] }}"
              class="vt-color-2 link"
              >{{ 'iNeedHelp.blocks.card1.link' | translate }}
            </a>
          </div>

          <div
            class="col-md-3 mycs9 wow animate__animated animate__fadeIn animate__slow"
            style="visibility: visible; animation-name: fadeIn"
          >
            <img src="../../../assets/my-loved-gethelp-2.jpg" class="img-fluid" />
            <h3 class="thmb-heading">{{ 'iNeedHelp.blocks.card2.title' | translate }}</h3>
            <div class="line-vt"></div>
            <p>
              <small>{{ 'iNeedHelp.blocks.card2.description' | translate }}</small>
            </p>
            <div class="line-vt-100 mb-10"></div>
            <a
              routerLink="/app/{{ (currentLang !== 'en' ? currentLang + '/' : '') + currentRouteMap['overdosePrevention'] }}"
              class="vt-color-2 link"
              >{{ 'iNeedHelp.blocks.card2.link' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="lovedoneneeds-for-you">
    <div class="container-fluid w-100 needhelp-sec3">
      <div class="row">
        <div class="col-md-5 img-section-hfy-vt"></div>

        <div class="col-md-3 mycs6 wow animate__animated animate__fadeInLeft animate__slow mt-4 pt-5 pb0">
          <div class="sce-head1">{{ 'iNeedHelp.reachOut2.title' | translate }}</div>
          <div class="line-vt-100 mb-10"></div>

          <h2 class="font-weight-bold mycs7">{{ 'iNeedHelp.reachOut2.heading' | translate }}</h2>
        </div>
        <div class="col-md-4 mycs8 wow animate__animated animate__fadeInDown animate__slow mt-40 pt-6 bt-call pb0">
          <p class="mt-2">
            <small>{{ 'iNeedHelp.reachOut2.description' | translate }}</small>
          </p>

          <div class="help-box wow animate__animated animate__fadeIn animate__slow mt-40" data-wow-delay=".8s">
            <div class="bt1 btn65">
              <a href="tel:8025655465" class="btn-get-started hero-button">{{ 'shared.buttons.call' | translate }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <a href="sms:8025655465;?&body=LINK" class="btn-get-started hero-button btn-connect">{{
                'shared.buttons.text' | translate
              }}</a>
            </div>
            <div class="bt2 top" hideForLang="es">
              <button class="btn-get-started hero-button" onclick="showchat()">{{ 'shared.buttons.chat' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
